.container {
  display: flex;
  border: solid 4px rgba(226, 20, 234, 0.4);
  border-radius: 8px;
  padding: 1rem;
  max-width: 80rem;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
  gap: 1rem;
}

.container img {
  height: 24rem;
}

.container .desc {
  /* font-weight: 500; */
  font-size: large;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.container .desc h2 {
  color: #0a7561;
  text-align: right;
  font-family: "Roboto", "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.container .desc div {
  flex-grow: 1;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.container .desc .share {
  opacity: 0.4;
  display: flex;
  align-items: flex-end;
  flex-grow: 0;
}

@media only screen and (max-width: 767px) {
  .container {
    flex-direction: column-reverse;
  }

  .container img {
    height: 12rem;
  }
}
