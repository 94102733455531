.header {
  background-color: #190f1a;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 1rem 1.5rem;
  position: fixed;
  width: 100%;
  z-index: 100;
}

.footer {
  background-color: #190f1a;
  color: white;
  display: flex;
  padding: 1rem 1rem;
  justify-content: center;
  margin-top: 1rem;
}

.footer a {
  text-decoration: underline;
}

.footer_inner {
  display: flex;
  gap: 1rem;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  height: 64px;
}

.menu {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.menu ul {
  list-style-type: none;
  padding: 0;
}

.menu ul li {
  display: inline;
  margin: 0 2rem;
}

a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

.menu a {
  font-size: larger;
  border-bottom: solid 2px transparent;
  flex-shrink: 0;
  font-weight: 500;
}

.menu a:hover {
  color: #e214ea;
}

nav .active-link {
  color: #e214ea;
  border-bottom: solid 2px;
  padding-bottom: 0.5rem;
  border-color: #e214ea;
}

.side-menu {
  padding: 1rem;
  flex-direction: column;
  background-color: #0a7561;
  width: 12rem;
  position: absolute;
  right: 0;
  display: none;
  height: 100vh;
  z-index: 50;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.6);
}

.side-menu ul {
  list-style-type: none;
  padding: 0 1rem;
}
.side-menu ul li {
  margin-bottom: 2.5rem;
}

.side-menu ul li a {
  font-size: large;
}

.side-menu .active-link {
  color: #ebba15;
  border-bottom: solid 2px;
  padding-bottom: 0.5rem;
  border-color: #ebba15;
}

.hamburger {
  display: none;
}

.hamburger button {
  background-color: transparent;
  border: none;
  color: white;
}

.MsoTitle {
  font-size: large;
  margin-bottom: 1rem;
  font-size: 26pt;
}

.WordSection1 {
  padding: 1rem;
  padding-top: 8rem;
}

.WordSection1 h2 {
  margin-top: 1rem;
  color: #0a7561;
}
.page-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 2rem;
  padding-top: 8rem;
  /* margin: 0 1rem; */
  margin-bottom: 4rem;
  min-height: 100vh;
  /* background-color: #0a7561; */
}

.page-container > h1 {
  font-family: "Roboto", sans-serif;
  display: inline-block;
  /* background-color: #0a7561; */
  color: white;
  padding: 2rem 2rem;
  border-radius: 8px;
  max-width: 80rem;
  width: 100%;
  text-align: center;
  /* background: linear-gradient(
    47deg,
    rgb(10, 117, 97) 46%,
    rgba(84, 84, 144, 0.9) 56%,
    rgba(157, 51, 190, 0.8) 66%,
    rgba(226, 20, 234, 0.7) 76%
  ); */
  border: solid 4px whitesmoke;
  color: whitesmoke;
  font-size: 2.5rem;
  text-shadow: 2px 2px 4px rgba(44, 44, 44, 0.8);
  /* background: #0a7561;
  background: linear-gradient(
    180deg,
    hsl(169, 84%, 22%),
    hsl(169, 84%, 30%) 50%,
    hsl(169, 84%, 22%)
  ); */
  background-image: url("/public/headerbg.svg");
  background-size: cover; /* Stretch the image to cover the entire area */
  background-repeat: no-repeat; /* Prevent the image from repeating */
  background-position: center; /* Center the image */
}

.page-container > span {
  display: inline-block;
  font-size: larger;
  font-weight: 600;
  padding: 1rem 2rem;
  border-radius: 8px;
  max-width: 80rem;
  width: 100%;
  text-align: center;
  padding: 0;
}

.page-container p {
  line-height: 160%;
}

.page-container__main {
  display: flex;
  flex-direction: column-reverse;
  border: solid 4px rgba(226, 20, 234, 0.4);
  border-radius: 8px;
  padding: 0.5rem;
  max-width: 80rem;
  width: 100%;
  align-items: center;
  gap: 1rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.page-container__pricing {
  display: flex;
  flex-direction: column;
  border: solid 4px rgba(226, 20, 234, 0.4);
  border-radius: 8px;
  padding: 0.5rem;
  max-width: 80rem;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
}

.page-container__about {
  display: flex;
  flex-direction: column;
  border: solid 4px rgba(226, 20, 234, 0.4);
  border-radius: 8px;
  padding: 1rem;
  max-width: 80rem;
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  background-color: whitesmoke;
  gap: 1rem;
}

.page-container__main img {
  height: 36rem;
}

.page-container__main__desc {
  /* max-width: 23rem; */
  padding: 0rem 1rem;
  /* padding-top: 0rem; */
  /* font-weight: 500; */
  font-size: large;
  display: flex;
  flex-direction: column;
  /* text-align: center; */
}

.page-container__pricing__desc {
  padding: 1rem;
  padding-top: 0rem;
  /* font-weight: 500; */
  font-size: large;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  margin-bottom: 1rem;
}

.page-container__about__desc {
  /* font-weight: 500; */
  font-size: large;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-container__pricing__desc h2 {
  color: #0a7561;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.page-container__about__desc h2 {
  color: #0a7561;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

.page-container__main__desc h2 {
  color: #0a7561;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin-bottom: 1rem;
}
.page-container__main__desc div {
  flex-grow: 1;
  /* text-align: right; */
  display: flex;
  align-items: center;
}

.cta {
  display: flex;
  gap: 4rem;
  align-items: center;
  width: 15rem;
}

.signup {
  border: solid 2px white;
  border-radius: 6px;
  padding: 0.25rem 1rem;
  background-color: #e214ea;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pretty-table {
  border-collapse: collapse;
  margin: 1rem auto;
  text-align: left;
}

.pretty-table thead tr {
  background-color: #0a7561;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.pretty-table th,
.pretty-table td {
  padding: 12px 15px;
}

.pretty-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.pretty-table tbody tr:nth-of-type(even) {
  background-color: rgba(10, 117, 97, 0.12);
}

.pretty-table tbody tr:last-of-type {
  border-bottom: 2px solid #0a7561;
}

.page-container__main__desc p a {
  color: #e214ea;
  font-size: large;
}

.page-container__doc_links ul {
  list-style: none;
}

.page-container__doc_links ul li div {
  display: flex;
  align-items: normal;
}

.page-container__doc_links ul li a {
  color: black;
  display: inline-block;
  line-height: 42px;
}

.page-container__doc_links ul li a:hover {
  text-decoration: underline;
}

.page-container__doc_back {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.page-container__doc_back a {
  color: black;
  display: block;
  text-align: right;
  font-size: smaller;
  line-height: 21px;
}

.page-container__doc_back a:hover {
  text-decoration: underline;
}

.share {
  display: flex;
  justify-content: flex-end;
  opacity: 0.4;
}

@media only screen and (max-width: 1440px) {
  a {
    font-size: medium;
  }
}

@media only screen and (max-width: 1280px) {
  .side-menu a {
    font-size: small;
  }
}

@media only screen and (max-width: 767px) {
  .hamburger {
    display: block;
  }

  .menu ul {
    display: none;
  }

  .header a {
    display: none;
  }

  .cta {
    display: none;
  }

  .side-menu {
    display: flex;
    padding-top: 8rem;
    height: 100vh;
  }

  .page-container {
    margin: 0 0.5rem;
  }
  .page-container h1 {
    /* background: #0a7561; */
    font-size: 160%;
  }

  /* .page-container__item {
    flex-direction: column-reverse;
  } */

  /* .page-container__item img {
    height: 12rem;
  } */
  .page-container__main img {
    height: 20rem;
  }

  .page-container__about img {
    width: 16rem;
  }

  .footer_inner {
    flex-direction: column;
    align-items: center;
  }
}
